import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ActivitiesService } from './api/activities.service';
import { AuthService } from './api/auth.service';
import { CollegesService } from './api/colleges.service';
import { ConventionsService } from './api/conventions.service';
import { CoursesService } from './api/courses.service';
import { DuesService } from './api/dues.service';
import { HealthService } from './api/health.service';
import { PaymentActivitiesService } from './api/paymentActivities.service';
import { PaymentsService } from './api/payments.service';
import { ReleasesService } from './api/releases.service';
import { S3Service } from './api/s3.service';
import { StudentsService } from './api/students.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ActivitiesService,
    AuthService,
    CollegesService,
    ConventionsService,
    CoursesService,
    DuesService,
    HealthService,
    PaymentActivitiesService,
    PaymentsService,
    ReleasesService,
    S3Service,
    StudentsService,
    UsersService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
