import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  closeResult: string;

  @ViewChild('content') modalContent: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    public authenticationService: AuthenticationService
  ) {
    this.loginForm = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      password: [, Validators.required],
      checkbox: [false],
    });
  }

  ngOnInit() {}

  showPassword() {
    this.show = !this.show;
  }

  login() {
    // console.log(this.loginForm.value);
    this.authenticationService.login(
      this.loginForm.value.email,
      this.loginForm.value.password,
      this.loginForm.value.checkbox,
      this.modalContent
    );
  }
}
