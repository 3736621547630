/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoginResponseDto { 
    email: string;
    password: string;
    token: string;
    name: string;
    lastName: string;
    phone: string;
    gender: LoginResponseDto.GenderEnum;
    dateOfBirth: Date;
    photoUrl: string;
    role: LoginResponseDto.RoleEnum;
    id: number;
    isActive: boolean;
}
export namespace LoginResponseDto {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
    export type RoleEnum = 'parent' | 'admin';
    export const RoleEnum = {
        Parent: 'parent' as RoleEnum,
        Admin: 'admin' as RoleEnum
    };
}