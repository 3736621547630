/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateCourseResponseDto } from './createCourseResponseDto';
import { CreateStudentResponseDto } from './createStudentResponseDto';

export interface CreatePaymentResponseDto { 
    monthPaid: CreatePaymentResponseDto.MonthPaidEnum;
    mount: number;
    monthPayment: Date;
    id: number;
    course: CreateCourseResponseDto;
    student: CreateStudentResponseDto;
    imageB64: string;
}
export namespace CreatePaymentResponseDto {
    export type MonthPaidEnum = 'marzo' | 'abril' | 'mayo' | 'junio' | 'julio' | 'agosto' | 'septiembre' | 'octubre' | 'noviembre' | 'diciembre';
    export const MonthPaidEnum = {
        Marzo: 'marzo' as MonthPaidEnum,
        Abril: 'abril' as MonthPaidEnum,
        Mayo: 'mayo' as MonthPaidEnum,
        Junio: 'junio' as MonthPaidEnum,
        Julio: 'julio' as MonthPaidEnum,
        Agosto: 'agosto' as MonthPaidEnum,
        Septiembre: 'septiembre' as MonthPaidEnum,
        Octubre: 'octubre' as MonthPaidEnum,
        Noviembre: 'noviembre' as MonthPaidEnum,
        Diciembre: 'diciembre' as MonthPaidEnum
    };
}