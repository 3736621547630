import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './shared/components/layout/content/content.component';
import { content, contentAdmin } from './shared/routes/routes';
import { LoginComponent } from './auth/login/login.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { AuthGuard } from './shared/guard/auth.guard';
import { HasSesionGuard } from './shared/guard/hasSesion.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: 'home/balance-page',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    redirectTo: 'admin/colleges-page',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  {
    path: 'admin',
    component: ContentComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: contentAdmin,
  },
  {
    path: 'auth/login',
    canActivate: [HasSesionGuard],
    component: LoginComponent,
  },
  {
    path: 'auth/forget-password',
    component: ForgetPasswordComponent,
  },

  {
    path: 'auth/reset-password',
    component: ResetPasswordComponent,
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy',
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
