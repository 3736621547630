import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class HasSesionGuard implements CanActivate {
  constructor(
    public authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authenticationService.isLoggedIn) {
      if (this.authenticationService.isAdmin) {
        this.router.navigate(['/admin']);
      } else {
        this.router.navigate(['/home']);
      }
    }
    return true;
  }
}
