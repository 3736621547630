/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateCollegeRequestDto { 
    name?: string;
    type?: string;
    region?: string;
    city?: string;
    comuna?: string;
    isActive?: boolean;
    doc1?: string;
    doc2?: string;
    doc3?: string;
}