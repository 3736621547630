<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink="/">
          <img
            class="img-fluid for-light"
            src="assets/images/logo/logo.png"
            alt=""
          />
          <img
            class="img-fluid for-dark"
            src="assets/images/logo/logo_dark.png"
            alt=""
          />
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons
          [icon]="'grid'"
          class="status_toggle middle"
        ></app-feather-icons>
      </div>
    </div>
    <div class="left-header col horizontal-wrapper pl-0"></div>
    <div class="nav-right col-10 pull-right right-header p-0">
      <ul class="nav-menus">
        <button *ngIf="!authenticationService.isAdmin" class="btn btn-primary pull-center" (click)="openModal()">
          Notificar Pago
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li> 
      </ul>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">
        {{ "Notificar Pago" }}
      </h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="theme-form" [formGroup]="newForm">
        <div class="form-group">
          <label class="col-form-label">Tipo</label>
              <ng-select
                id="select"
                formControlName="type"
                placeholder="Seleccione un Tipo de Pago "
                [loading]="Cargando"
                notFoundText="Tipo no encontrado"
              >
                <ng-option *ngFor="let type of types" [value]="type.value"
                  >{{ type.name }}
                </ng-option>
              </ng-select>

              <div
                *ngIf="
                  newForm.controls.type.touched &&
                  newForm.controls.type.errors?.required
                "
                class="text text-danger mt-1"
              >
                El Tipo es requerido
              </div>
              <div *ngIf="newForm.controls.type.value == 'cuota'">
              <label class="col-form-label">Mes</label>
              <ng-select
                id="select"
                [multiple]="true"
                formControlName="valueType"
                placeholder="Seleccione Mes de Pago "
                [loading]="Cargando"
                notFoundText="Mes no encontrado"
              >
                <ng-option *ngFor="let month of filterMonths()" [value]="month.month"
                  >{{ month.month }}
                </ng-option>
              </ng-select>
              <div
                *ngIf="
                  newForm.controls.valueType.touched &&
                  newForm.controls.valueType.errors?.required
                "
                class="text text-danger mt-1"
              >
                El Mes es requerido
              </div>
              <div *ngIf="newForm.controls.type.value == 'cuota'">
                <label class="col-form-label">Monto</label>
                <div
                  class="text-primary total"
                >
                  {{due.mount * newForm.controls.valueType.value.length| currency: "CLP"}}
                </div>
              </div>
            </div>
            <div *ngIf="newForm.controls.type.value == 'actividad'">
              <label class="col-form-label">Actividades</label>
              <ng-select
                id="select"
                formControlName="valueType"
                placeholder="Seleccione Actividad a Pagar "
                [loading]="Cargando"
                notFoundText="Actividad no encontrada"
              >
                <ng-option  *ngFor="let activity of activityData.items" [value]="activity"
                  >{{ activity.title }}
                </ng-option>
              </ng-select>
              <div
                *ngIf="
                  newForm.controls.valueType.touched &&
                  newForm.controls.valueType.errors?.required
                "
                class="text text-danger mt-1"
              >
                La Actividad es requerida
              </div>
            </div>
            <div *ngIf="newForm.controls.type.value == 'actividad'">
              <label class="col-form-label">Monto</label>
              <div
                class="text-primary total"
              >
                {{newForm.controls.valueType.value?.mount | currency: "CLP"}}
              </div>
            </div>
          <label class="col-form-label">Archivo</label>
          <ngx-file-drop
              [showBrowseBtn]="false"
              [multiple]="false"
              accept="image/*,application/pdf"
              dropZoneClassName="dropzone-custom"
              dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)"
              contentClassName="dropzone-content"
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <button
                  class="btn btn-light full-width"
                  (click)="openFileSelector()"
                >
                  Subir Comprobante
                </button>
              </ng-template>
            </ngx-file-drop>
            <div *ngIf="newForm.controls.imageB64.value">
              <ul class="list-group m-t-10">
                <li class="list-group-item">
                  Archivo Subido
                  <app-feather-icons
                    (click)="removeFile()"
                    class="delete-file"
                    [icon]="'trash-2'"
                  ></app-feather-icons>
                </li>
              </ul>
            </div>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-primary"
        [class.loader--text]="showLoader"
        [disabled]="!newForm.valid || showLoader"
        type="submit"
        (click)="changedPayment()"
      >
        {{ "Notificar Pago" }}
      </button>
    </div>
  </ng-template>
</div>
<!-- Page Header Ends -->
