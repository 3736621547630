<div class="media profile-media">
  <div class="media-body">
    <span
      >{{ authenticationService.userData.name }}
      {{ authenticationService.userData.lastName }}</span
    >

    <p class="mb-0 font-roboto" *ngIf="authenticationService.currentStudent">
      {{ authenticationService.currentStudent.name }}
      {{ authenticationService.currentStudent.lastName }} -
      {{ authenticationService.currentStudent.course.name }}
      {{ authenticationService.currentStudent.course.college.name }}
      <i class="middle fa fa-angle-down"></i>
    </p>
    <p class="mb-0 font-roboto" *ngIf="!authenticationService.currentStudent">
      No posee un estudiante asociado
      <i class="middle fa fa-angle-down"></i>
    </p>
  </div>

  <div class="avatars">
    <div class="avatar">
      <img
        class="img-40-40 rounded-circle"
        src="https://ui-avatars.com/api/?name={{
          authenticationService.userData?.name
        }}+{{
          authenticationService.userData?.lastName
        }}&background=0079ff&color=fff&size=40&font-size=0.43&rounded=true"
        alt="#"
      />

      <svg
        *ngIf="
          authenticationService.currentStudent?.isMember ||
          authenticationService.isAdmin
        "
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-star is-admin"
      >
        <polygon
          points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
        ></polygon>
      </svg>
    </div>
  </div>
</div>

<!--  -->
<ul class="profile-dropdown onhover-show-div">
  <li *ngFor="let student of authenticationService.userData.students">
    <a (click)="changeCurrentStudend(student)">
      <!-- <app-feather-icons
        [ngClass]="{
          'text-primary': student.id === authenticationService.currentStudent.id
        }"
        *ngIf="student.id === authenticationService.currentStudent.id"
        [icon]="'home'"
      ></app-feather-icons> -->
      <span
        [ngClass]="{
          'text-primary': student.id === authenticationService.currentStudent.id
        }"
      >
        {{ student.name }}
        {{ student.lastName }} -
        {{ student.course.name }}
        {{ student.course.college.name }}</span
      >
    </a>
  </li>

  <li
    *ngIf="
      !authenticationService.isAdmin &&
      authenticationService.currentStudent.isMember
    "
  >
    <a (click)="openModal()"><span>Modificar mis Datos</span></a>
  </li>
  <li>
    <a (click)="signOut()"><span>Cerrar Sesión</span></a>
  </li>
</ul>
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">MODIFICAR MIS DATOS</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form class="theme-form" [formGroup]="newForm">
      <!-- <div class="row">
        <div class="col-12 col-md-3">
          <div class="avatars">
            <div class="avatar">
              <img
                class="img-100-100 m-r-10 rounded-circle"
                src="{{
                  newForm.value.photoUrl
                    ? newForm.value.photoUrl
                    : 'assets/images/placeholder-img.png'
                }}"
                alt="#"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-9 align-self-center">
          <ngx-file-drop
            [showBrowseBtn]="false"
            [multiple]="false"
            accept="image/*"
            dropZoneClassName="dropzone-custom"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
            contentClassNama="dropzone-content"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <button class="btn btn-light" (click)="openFileSelector()">
                Subir Foto
              </button>
            </ng-template>
          </ngx-file-drop>
          <button class="btn btn-outline-dark m-l-10" (click)="removeFile()">
            Eliminar
          </button>
          <p class="m-t-10 sub-title-info">
            Recomendamos subir una foto en la que se pueda ver claramente la
            cara del apoderado y facilitar el reconocimiento entre los
            apoderados.
          </p>
        </div>
      </div> -->

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Nombre</label>
            <input
              class="form-control"
              type="text"
              required=""
              placeholder="Juan"
              formControlName="name"
            />
            <div
              *ngIf="
                newForm.controls.name.touched &&
                newForm.controls.name.errors?.required
              "
              class="text text-danger mt-1"
            >
              El nombre es requerido
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Apellido</label>
            <input
              class="form-control"
              type="text"
              required=""
              placeholder="Pérez"
              formControlName="lastName"
            />
            <div
              *ngIf="
                newForm.controls.lastName.touched &&
                newForm.controls.lastName.errors?.required
              "
              class="text text-danger mt-1"
            >
              El apellido es requerido
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Correo</label>
            <input
              class="form-control"
              type="email"
              required=""
              placeholder="apoderado@ladirectiva.cl"
              formControlName="email"
            />
            <div
              *ngIf="
                newForm.controls.email.touched &&
                newForm.controls.email.errors?.required
              "
              class="text text-danger mt-1"
            >
              El correo es requerido
            </div>
            <div
              *ngIf="
                newForm.controls.email.touched &&
                newForm.controls.email.errors?.email
              "
              class="text text-danger mt-1"
            >
              Correo inválido
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Teléfono (opcional)</label>
            <input
              class="form-control"
              type="text"
              placeholder="+56959470502"
              formControlName="phone"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Género</label>
            <ng-select
              id="select"
              formControlName="gender"
              placeholder="Seleccione un genero "
              [loading]="Cargando"
              notFoundText="Género no encontrada"
            >
              <ng-option *ngFor="let gender of genders" [value]="gender.value"
                >{{ gender.name }}
              </ng-option>
            </ng-select>

            <div
              *ngIf="
                newForm.controls.gender.touched &&
                newForm.controls.gender.errors?.required
              "
              class="text text-danger mt-1"
            >
              El género es requerido
            </div>
          </div>
        </div>
        <div class="col custom-datepicker custom-datepicker-dropdown">
          <div class="form-group">
            <label class="col-form-label">Fecha Nacimiento</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd/mm/yyyy"
                name="dp"
                [minDate]="{
                  year: 1900,
                  month: 1,
                  day: 1
                }"
                [readonly]="true"
                ngbDatepicker
                formControlName="dateOfBirth"
                #f="ngbDatepicker"
              />
              <div class="input-group-append">
                <button class="btn calendar" (click)="f.toggle()" type="button">
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
            <div
              *ngIf="
                newForm.controls.dateOfBirth.touched &&
                newForm.controls.dateOfBirth.errors?.required
              "
              class="text text-danger mt-1"
            >
              La fecha nacimiento es requerido
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Nombre Alumno</label>
            <input
              class="form-control"
              type="text"
              required=""
              placeholder="Diego"
              formControlName="studentName"
            />
            <div
              *ngIf="
                newForm.controls.studentName.touched &&
                newForm.controls.studentName.errors?.required
              "
              class="text text-danger mt-1"
            >
              El nombre del alumno es requerido
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Apellido Alumno</label>
            <input
              class="form-control"
              type="text"
              required=""
              placeholder="Pérez"
              formControlName="studentlastName"
            />
            <div
              *ngIf="
                newForm.controls.studentlastName.touched &&
                newForm.controls.studentlastName.errors?.required
              "
              class="text text-danger mt-1"
            >
              El apellido del alumno es requerido
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label class="col-form-label">Género Alumno</label>
            <ng-select
              id="sselect"
              formControlName="studentGender"
              placeholder="Seleccione un genero "
              [loading]="Cargando"
              notFoundText="Género no encontrada"
            >
              <ng-option *ngFor="let gender of genders" [value]="gender.value"
                >{{ gender.name }}
              </ng-option>
            </ng-select>

            <div
              *ngIf="
                newForm.controls.studentGender.touched &&
                newForm.controls.studentGender.errors?.required
              "
              class="text text-danger mt-1"
            >
              El género del alumno es requerido
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-6 custom-datepicker custom-datepicker-dropdown"
        >
          <div class="form-group">
            <label class="col-form-label">Fecha Nacimiento Alumno</label>
            <div class="input-group">
              <input
                class="form-control"
                placeholder="dd/mm/yyyy"
                name="sdp"
                [minDate]="{
                  year: 1900,
                  month: 1,
                  day: 1
                }"
                [readonly]="true"
                ngbDatepicker
                formControlName="studentDateOfBirth"
                #sf="ngbDatepicker"
              />
              <div class="input-group-append">
                <button
                  class="btn calendar"
                  (click)="sf.toggle()"
                  type="button"
                >
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
            <div
              *ngIf="
                newForm.controls.studentDateOfBirth.touched &&
                newForm.controls.studentDateOfBirth.errors?.required
              "
              class="text text-danger mt-1"
            >
              La fecha nacimiento del alumno es requerido
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-between">
    <button
      type="button"
      class="btn btn-primary"
      [class.loader--text]="showLoader"
      [disabled]="!newForm.valid || showLoader"
      type="submit"
      (click)="modal.close()"
    >
      Actualizar Datos
    </button>
  </div>
</ng-template>
