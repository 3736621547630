import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // Full screen
  public fullScreen: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.setScreenWidth(window.innerWidth);
    // fromEvent(window, 'resize')
    //   .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
    //   .subscribe((evt: any) => {
    //     this.setScreenWidth(evt.target.innerWidth);
    //     if (evt.target.innerWidth < 991) {
    //       this.collapseSidebar = true;
    //       this.megaMenu = false;
    //       this.levelMenu = false;
    //     }
    //     if (evt.target.innerWidth < 1199) {
    //       this.megaMenuColapse = true;
    //     }
    //   });
    // if (window.innerWidth < 991) {
    //   // Detect Route change sidebar close
    //   this.router.events.subscribe((event) => {
    //     this.collapseSidebar = true;
    //     this.megaMenu = false;
    //     this.levelMenu = false;
    //   });
    // }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }


  MENUITEMS_ADMIN: Menu[] = [
    {
      path: '/admin/colleges-page',
      title: 'Instituciones',
      type: 'link',
      icon: 'students',
      active: true,
    },
    /*{
      path: '/admin/courses-page',
      title: 'Cursos',
      type: 'link',
      icon: 'releases',
    },
    {
      path: '/admin/students-page',
      title: 'Apoderados',
      type: 'link',
      icon: 'students',
    },*/

  ];

  MENUITEMS: Menu[] = [
    {
      path: '/home/balance-page',
      title: 'Balance',
      type: 'link',
      icon: 'balance',
    },
    {
      path: '/home/students-course-page',
      title: 'Apoderados',
      type: 'link',
      icon: 'students',
    },
    {
      path: '/home/payments-page',
      title: 'Cuotas',
      type: 'link',
      icon: 'payments',
    },
    {
      path: '/home/activities-page',
      title: 'Actividades',
      type: 'link',
      icon: 'activities',
    },
    {
      path: '/home/conventions-page',
      title: 'Asamblea',
      type: 'link',
      icon: 'conventions',
    },
    {
      path: '/home/releases-page',
      title: 'Comunicados',
      type: 'link',
      icon: 'releases',
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(
    this.authenticationService.isAdmin ? this.MENUITEMS_ADMIN : this.MENUITEMS
  );
}
