<div class="container-fluid">
  <div class="row">
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-left" href="#">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h3>Ingresa a tu <span>Directiva</span></h3>
              <p>Cuentas claras, <span>conservan la amistad</span></p>
              <div class="form-group">
                <label class="col-form-label">Correo</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="apoderado@ladirectiva.cl"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  El correo es requerido
                </div>
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.email
                  "
                  class="text text-danger mt-1"
                >
                  Correo inválido
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  formControlName="password"
                  minlength="8"
                  maxlength="50"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña es requerida
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.minlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener al menos 8 caracteres
                </div>
                <div
                  *ngIf="
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors?.maxlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener máximo 50 caracteres
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="checkbox p-0">
                  <input
                    id="checkbox1"
                    type="checkbox"
                    formControlName="checkbox"
                  />
                  <label class="text-muted" for="checkbox1"
                    >Recordar contraseña</label
                  >
                </div>
                <a [routerLink]="'/auth/forget-password'" class="link"
                  >¿Olvidó su contraseña?</a
                >
                <div class="wrapp-btn m-t-30 m-b-50">
                  <button
                    class="btn btn-primary btn-pill btn-lg"
                    [class.loader--text]="authenticationService.showLoader"
                    [disabled]="
                      !loginForm.valid || authenticationService.showLoader
                    "
                    (click)="login()"
                    type="submit"
                  >
                    <span>
                      {{
                        authenticationService.showLoader ? "" : "INICIAR SESIÓN"
                      }}
                    </span>
                  </button>
                  <p class="mt-4 mb-0">
                    <a class="ml-2" href="https://ladirectiva.cl">Volver</a>
                  </p>
                </div>
              </div>

              <p class="mt-4 mb-0">
                ¿No tienes una cuenta?
                <a
                  class="ml-2"
                  target="_blank"
                  href="https://ladirectiva.cl/ventas"
                  >Contactar ejecutivo</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xl-7"
      [ngStyle]="{
        'background-image': 'url(assets/images/login/background.jpeg)',
        'background-size': 'cover',
        'background-position': 'center center',
        display: 'block'
      }"
    >
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/background.jpeg"
        alt="looginpage"
      />
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">SELECIONAR ESTUDIANTE</h5>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="">
        <div class="form-group">
          <label class="col-form-label">Estudiante</label>

          <ng-select
            id="select"
            [(ngModel)]="authenticationService.currentStudent"
            [ngModelOptions]="{ standalone: true }"
          >
            <ng-option
              *ngFor="let student of authenticationService.students"
              [value]="student"
              >{{ student.name }} {{ student.lastName }} -
              {{ student.course.name }} {{ student.course.college.name }}
            </ng-option>
          </ng-select>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-between">
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!authenticationService.currentStudent"
        (click)="modal.close()"
      >
        Seleccionar
      </button>
    </div>
  </ng-template>
</div>
