import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public show: boolean = false;
  public confirmShow: boolean = false;
  public resetPasswordForm: FormGroup;
  public errorMessage: any;
  closeResult: string;

  token;

  constructor(
    private fb: FormBuilder,
    public authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.resetPasswordForm = this.fb.group({
      password: [, Validators.required],
      confirmPassword: [, Validators.required],
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.token = params.token;
    });
  }

  showPassword() {
    this.show = !this.show;
  }

  showConfirmPassword() {
    this.confirmShow = !this.confirmShow;
  }

  resetPassword() {
    console.log(this.resetPasswordForm.value);
    this.authenticationService.resetPassword(
      this.resetPasswordForm.value.password,
      this.token
    );
  }
}
