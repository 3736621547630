/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateParentRequestDto { 
    email: string;
    name: string;
    lastName: string;
    phone: string;
    gender: CreateParentRequestDto.GenderEnum;
    dateOfBirth: Date;
    photoUrl: string;
    studentName: string;
    studentlastName: string;
    studentGender: CreateParentRequestDto.StudentGenderEnum;
    studentDateOfBirth: Date;
    isMember: boolean;
    course: number;
    courseName: string;
    institution: string;
}
export namespace CreateParentRequestDto {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
    export type StudentGenderEnum = 'male' | 'female';
    export const StudentGenderEnum = {
        Male: 'male' as StudentGenderEnum,
        Female: 'female' as StudentGenderEnum
    };
}