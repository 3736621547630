import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () =>
      import('../../components/sample/sample.module').then(
        (m) => m.SampleModule
      ),
  },
  {
    path: 'students-course-page',
    loadChildren: () =>
      import('../../components/students-course/students-course.module').then(
        (m) => m.StudentsCourseModule
      ),
  },
  {
    path: 'releases-page',
    loadChildren: () =>
      import('../../components/releases/releases.module').then(
        (m) => m.ReleasesModule
      ),
  },
  {
    path: 'payments-page',
    loadChildren: () =>
      import('../../components/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: 'payments-detail-page',
    loadChildren: () =>
      import('../../components/payments-detail/payments-detail.module').then(
        (m) => m.PaymentsDetailModule
      ),
  },
  {
    path: 'conventions-page',
    loadChildren: () =>
      import('../../components/conventions/conventions.module').then(
        (m) => m.ConventionsModule
      ),
  },
  {
    path: 'activities-page',
    loadChildren: () =>
      import('../../components/activities/activities.module').then(
        (m) => m.ActivitiesModule
      ),
  },
  {
    path: 'activities-detail-page',
    loadChildren: () =>
      import(
        '../../components/activities-detail/activities-detail.module'
      ).then((m) => m.ActivitiesDetailModule),
  },
  {
    path: 'balance-page',
    loadChildren: () =>
      import('../../components/balance/balance.module').then(
        (m) => m.BalanceModule
      ),
  },
  {
    path: 'students-page',
    loadChildren: () =>
      import('../../components/students/students.module').then(
        (m) => m.StudentsModule
      ),
  },
];

export const contentAdmin: Routes = [
  {
    path: 'colleges-page',
    loadChildren: () =>
      import('../../components/colleges/colleges.module').then(
        (m) => m.CollegesModule
      ),
  },
  {
    path: 'courses-page',
    loadChildren: () =>
      import('../../components/courses/courses.module').then(
        (m) => m.CoursesModule
      ),
  },
  {
    path: 'students-page',
    loadChildren: () =>
      import('../../components/students/students.module').then(
        (m) => m.StudentsModule
      ),
  },
];
