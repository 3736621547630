<div class="container-fluid">
  <div class="row">
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-left" href="#">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="forgotForm">
              <h3>Ingresa a tu <span>Directiva</span></h3>
              <p>Cuentas claras, <span>conservan la amistad</span></p>
              <div class="form-group">
                <label class="col-form-label">Correo</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="apoderado@ladirectiva.cl"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    forgotForm.controls.email.touched &&
                    forgotForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  El correo es requerido
                </div>
                <div
                  *ngIf="
                    forgotForm.controls.email.touched &&
                    forgotForm.controls.email.errors?.email
                  "
                  class="text text-danger mt-1"
                >
                  Correo inválido
                </div>
              </div>

              <div class="form-group mb-0">
                <div class="wrapp-btn m-t-30 m-b-50">
                  <button
                    class="btn btn-primary btn-pill btn-lg m-t-10"
                    [class.loader--text]="authenticationService.showLoader"
                    [disabled]="
                      !forgotForm.valid || authenticationService.showLoader
                    "
                    (click)="forgetPassword()"
                    type="submit"
                  >
                    <span>
                      {{ authenticationService.showLoader ? "" : "RECUPERAR " }}
                    </span>
                  </button>
                  <p class="mt-4 mb-0">
                    <a class="ml-2" href="https://app.ladirectiva.cl/#/auth/login"
                      >Volver</a
                    >
                  </p>
                </div>
              </div>
              <p class="mt-4 mb-0">
                ¿No tienes una cuenta?
                <a
                  class="ml-2"
                  target="_blank"
                  href="https://ladirectiva.cl/ventas"
                  >Contactar ejecutivo</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xl-7"
      [ngStyle]="{
        'background-image': 'url(assets/images/login/background.jpeg)',
        'background-size': 'cover',
        'background-position': 'center center',
        display: 'block'
      }"
    >
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/background.jpeg"
        alt="looginpage"
      />
    </div>
  </div>
</div>
