/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateBankingAccountRequestDto { 
    accountType: UpdateBankingAccountRequestDto.AccountTypeEnum;
    accountNumber: string;
    bank: string;
    accountHolder: string;
}
export namespace UpdateBankingAccountRequestDto {
    export type AccountTypeEnum = 'vista' | 'corriente';
    export const AccountTypeEnum = {
        Vista: 'vista' as AccountTypeEnum,
        Corriente: 'corriente' as AccountTypeEnum
    };
}