<div class="container-fluid">
  <div class="row">
    <div class="col-xl-5 p-0">
      <div class="login-card">
        <div>
          <div>
            <a class="logo text-left" href="#">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/logo.png"
                alt="looginpage"
              />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="resetPasswordForm">
              <h3>Ingresa a tu <span>Directiva</span></h3>
              <p>Cuentas claras, <span>conservan la amistad</span></p>

              <div class="form-group">
                <label class="col-form-label"><b>Crear</b> Contraseña</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  formControlName="password"
                  minlength="8"
                  maxlength="50"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <span class="show"></span>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.password.touched &&
                    resetPasswordForm.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña es requerida
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.password.touched &&
                    resetPasswordForm.controls.password.errors?.minlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener al menos 8 caracteres
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.password.touched &&
                    resetPasswordForm.controls.password.errors?.maxlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener máximo 50 caracteres
                </div>
              </div>

              <div class="form-group">
                <label class="col-form-label">Repetir contraseña</label>
                <input
                  class="form-control"
                  [type]="confirmShow ? 'text' : 'password'"
                  name="login[confirmPassword]"
                  required=""
                  placeholder="*********"
                  formControlName="confirmPassword"
                  minlength="8"
                  maxlength="50"
                />
                <div
                  class="show-hide"
                  (click)="showConfirmPassword()"
                  *ngIf="!confirmShow"
                >
                  <span class="show"></span>
                </div>
                <div
                  class="show-hide"
                  (click)="showConfirmPassword()"
                  *ngIf="confirmShow"
                >
                  <span class="Hide"></span>
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.confirmPassword.touched &&
                    resetPasswordForm.controls.confirmPassword.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña es requerida
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.confirmPassword.touched &&
                    resetPasswordForm.controls.confirmPassword.errors?.minlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener al menos 8 caracteres
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.confirmPassword.touched &&
                    resetPasswordForm.controls.confirmPassword.errors?.maxlength
                  "
                  class="text text-danger mt-1"
                >
                  La contraseña debe tener máximo 50 caracteres
                </div>
                <div
                  *ngIf="
                    resetPasswordForm.controls.confirmPassword.touched &&
                    resetPasswordForm.controls.confirmPassword.value !=
                      resetPasswordForm.controls.password.value
                  "
                  class="text text-danger mt-1"
                >
                  La contraseñas deben ser iguales
                </div>
              </div>
              <div class="form-group mb-0">
                <div class="wrapp-btn">
                  <button
                    class="btn btn-primary btn-pill btn-lg m-t-10"
                    [class.loader--text]="authenticationService.showLoader"
                    [disabled]="
                      !resetPasswordForm.valid ||
                      resetPasswordForm.controls.confirmPassword.value !=
                        resetPasswordForm.controls.password.value ||
                      authenticationService.showLoader
                    "
                    (click)="resetPassword()"
                    type="submit"
                  >
                    <span>
                      {{ authenticationService.showLoader ? "" : "CONFIRMAR" }}
                    </span>
                  </button>
                </div>
              </div>
              <p class="mt-4 mb-0">
                ¿No tienes una cuenta?
                <a
                  class="ml-2"
                  target="_blank"
                  href="https://ladirectiva.cl/ventas"
                  >Contactar ejecutivo</a
                >
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-xl-7"
      [ngStyle]="{
        'background-image': 'url(assets/images/login/background.jpeg)',
        'background-size': 'cover',
        'background-position': 'center center',
        display: 'block'
      }"
    >
      <img
        class="bg-img-cover bg-center d-none"
        src="assets/images/login/background.jpeg"
        alt="looginpage"
      />
    </div>
  </div>
</div>
