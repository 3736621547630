/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreatePaymentRequestDto } from '../model/createPaymentRequestDto';
import { ResponseWrapperForBoolean } from '../model/responseWrapperForBoolean';
import { ResponseWrapperForCreatePaymentResponseDto } from '../model/responseWrapperForCreatePaymentResponseDto';
import { ResponseWrapperForListPaymentResponseDto } from '../model/responseWrapperForListPaymentResponseDto';
import { ResponseWrapperForNumber } from '../model/responseWrapperForNumber';
import { UpdatePaymentRequestDto } from '../model/updatePaymentRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PaymentsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerCount(observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForNumber>;
    public paymentsControllerCount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForNumber>>;
    public paymentsControllerCount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForNumber>>;
    public paymentsControllerCount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForNumber>('get',`${this.basePath}/v1/payments/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create payment
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerCreate(body: CreatePaymentRequestDto, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreatePaymentResponseDto>;
    public paymentsControllerCreate(body: CreatePaymentRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreatePaymentResponseDto>>;
    public paymentsControllerCreate(body: CreatePaymentRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreatePaymentResponseDto>>;
    public paymentsControllerCreate(body: CreatePaymentRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling paymentsControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForCreatePaymentResponseDto>('post',`${this.basePath}/v1/payments`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete payment
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public paymentsControllerDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public paymentsControllerDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public paymentsControllerDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling paymentsControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForBoolean>('delete',`${this.basePath}/v1/payments/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get payment
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerFindById(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreatePaymentResponseDto>;
    public paymentsControllerFindById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreatePaymentResponseDto>>;
    public paymentsControllerFindById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreatePaymentResponseDto>>;
    public paymentsControllerFindById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling paymentsControllerFindById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForCreatePaymentResponseDto>('get',`${this.basePath}/v1/payments/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List payment
     * 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerList(limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListPaymentResponseDto>;
    public paymentsControllerList(limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListPaymentResponseDto>>;
    public paymentsControllerList(limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListPaymentResponseDto>>;
    public paymentsControllerList(limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListPaymentResponseDto>('get',`${this.basePath}/v1/payments`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List payments by course
     * 
     * @param year 
     * @param course 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerListByCourse(year: number, course: number, limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListPaymentResponseDto>;
    public paymentsControllerListByCourse(year: number, course: number, limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListPaymentResponseDto>>;
    public paymentsControllerListByCourse(year: number, course: number, limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListPaymentResponseDto>>;
    public paymentsControllerListByCourse(year: number, course: number, limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling paymentsControllerListByCourse.');
        }

        if (course === null || course === undefined) {
            throw new Error('Required parameter course was null or undefined when calling paymentsControllerListByCourse.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListPaymentResponseDto>('get',`${this.basePath}/v1/payments/course/${encodeURIComponent(String(course))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update payment
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paymentsControllerPut(body: UpdatePaymentRequestDto, id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public paymentsControllerPut(body: UpdatePaymentRequestDto, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public paymentsControllerPut(body: UpdatePaymentRequestDto, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public paymentsControllerPut(body: UpdatePaymentRequestDto, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling paymentsControllerPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling paymentsControllerPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForBoolean>('put',`${this.basePath}/v1/payments/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
