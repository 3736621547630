/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdatePaymentActivityMovementRequest { 
    title: string;
    doc: string;
    kindMovement: UpdatePaymentActivityMovementRequest.KindMovementEnum;
    mount: number;
    monthPaymentActivity: Date;
}
export namespace UpdatePaymentActivityMovementRequest {
    export type KindMovementEnum = 'gasto' | 'ingreso' | 'cuota';
    export const KindMovementEnum = {
        Gasto: 'gasto' as KindMovementEnum,
        Ingreso: 'ingreso' as KindMovementEnum,
        Cuota: 'cuota' as KindMovementEnum
    };
}