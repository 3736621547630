import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { HelperService } from '../../../../../../app/shared/services/helper.service';
import { S3Service, StudentsService } from '../../../../../backend';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  @ViewChild('content') modalContent: TemplateRef<any>;

  ColumnMode = ColumnMode;
  newForm: FormGroup;
  course;
  isEdit = false;
  showLoader = false;

  genders = [
    { name: 'Masculino', value: 'male' },
    { name: 'Femenino', value: 'female' },
    { name: 'Prefiero no decirlo', value: 'other' },
  ];

  curretFileUrl = {
    url: '',
    signedUrl: '',
  };

  constructor(
    public authenticationService: AuthenticationService,
    private studentsService: StudentsService,
    private fb: FormBuilder,
    private http: HttpClient,
    private s3Service: S3Service,
    private modalService: NgbModal,
    private helperService: HelperService
  ) {
    this.newForm = this.fb.group({
      name: [, [Validators.required]],
      lastName: [, Validators.required],
      email: [, [Validators.required, Validators.email]],
      phone: [, []],
      gender: [, [Validators.required]],
      dateOfBirth: [, [Validators.required]],
      studentName: [, [Validators.required]],
      studentlastName: [, Validators.required],
      studentGender: [, [Validators.required]],
      studentDateOfBirth: [, [Validators.required]],
      photoUrl: [, []],
    });
  }

  ngOnInit() {}

  changeCurrentStudend(student) {
    this.authenticationService.currentStudent = student;
    localStorage.setItem('student', JSON.stringify(student));
    this.authenticationService.$course.next(
      this.authenticationService.currentStudent.course
    );
    this.course = student.course.id;
  }

  signOut() {
    this.authenticationService.signOut();
  }
  async openModal() {
    try {
      // this.newForm.patchValue({ course: parseInt(this.course, 10) });

      console.log('editValue', this.authenticationService.userData);
      console.log('editValue', this.authenticationService.currentStudent);
      console.log(
        ' this.authenticationService.userData.dateOfBirth',
        this.helperService.parseDate(
          this.authenticationService.userData.dateOfBirth
        )
      );
      this.newForm.patchValue({
        dateOfBirth: this.helperService.parseDate(
          this.authenticationService.userData.dateOfBirth
        ),
        gender: this.authenticationService.userData.gender,
        lastName: this.authenticationService.userData.lastName,
        name: this.authenticationService.userData.name,
        email: this.authenticationService.userData.email,
        phone: this.authenticationService.userData.phone,
        studentName: this.authenticationService.currentStudent.name,
        studentlastName: this.authenticationService.currentStudent.lastName,
        studentGender: this.authenticationService.currentStudent.gender,
        studentDateOfBirth: this.helperService.parseDate(
          this.authenticationService.currentStudent.dateOfBirth
        ),
        photoUrl: this.authenticationService.userData.photoUrl,
      });
    

      await this.modalService.open(this.modalContent, {
        windowClass: 'dark-modal',
        centered: true,
      }).result;
      this.showLoader = true;
      
      await this.studentsService
        .studentsControllerUpdateParant(
          {
            ...this.newForm.getRawValue(),
            isMember: this.authenticationService.currentStudent.isMember,
            course: this.authenticationService.currentStudent.course.id,
          },
          this.authenticationService.currentStudent.id
        )
        .toPromise();

      const user = JSON.parse(localStorage.getItem('user'));

      console.log('user', user);
      if (user) {
        this.authenticationService.userData.name =
          this.newForm.getRawValue().name;
        this.authenticationService.userData.lastName =
          this.newForm.getRawValue().lastName;
        user.name = this.authenticationService.userData.name;
        user.lastName = this.authenticationService.userData.lastName;

        localStorage.setItem('user', JSON.stringify(user));
      }
      const currentStudent = JSON.parse(localStorage.getItem('student'));
      console.log('currentStudent', currentStudent);

      if (currentStudent) {
        this.authenticationService.currentStudent.name =
          this.newForm.getRawValue().studentName;
        this.authenticationService.currentStudent.lastName =
          this.newForm.getRawValue().studentlastName;
        currentStudent.name = this.authenticationService.currentStudent.name;
        currentStudent.lastName =
          this.authenticationService.currentStudent.lastName;
        localStorage.setItem('student', JSON.stringify(currentStudent));
      }
      this.showLoader = false;
    } catch (error) {
      this.showLoader = false;
      console.log(error);
    }
  }

  async generateS3Url() {
    const { data } = await this.s3Service.s3ControllerUploadFile().toPromise();
    return data;
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      fileEntry.file(async (file: File) => {
        const headers = new HttpHeaders({
          'Content-Type': file.type,
        });

        const urlData = await this.generateS3Url();
        this.http.put(urlData.signedUrl, file, { headers }).subscribe(() => {
          this.newForm.patchValue({ photoUrl: urlData.url });
          console.log('File is uploaded');
        });
      });
    }
  }

  removeFile() {
    this.newForm.patchValue({ photoUrl: '' });
  }
}
