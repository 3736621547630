import { Injectable, OnInit, NgZone } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class HelperService implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  parseDate(date) {
    const dateArray = new Date(date).toISOString().split('T')[0].split('-');
    return dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
  }

  parseDateTime(date){
    const dateAux = new Date(date);
    dateAux.setHours(12);
    return dateAux
  }

  parseTimeToDate(time) {
    const date = new Date();
    date.setHours(time.hour);
    date.setMinutes(time.minute);
    date.setSeconds(time.second);
    return date;
  }

  currentYear() {
    const dateArray = new Date().toISOString().split('T')[0].split('-');
    return parseInt(dateArray[0], 10);
  }

  getYear(data) {
    const dateArray = new Date(data).toISOString().split('T')[0].split('-');
    return parseInt(dateArray[0], 10);
  }

  getMonth(data) {
    const dateArray = new Date(data).toISOString().split('T')[0].split('-');
    return parseInt(dateArray[1], 10);
  }

  generateArrayYear(date: string) {
    const dateArray = new Date(date).toISOString().split('T')[0].split('-');
    const year = parseInt(dateArray[0], 10);
    const currentYear = this.currentYear();
    const years = [];
    for (let i = year; i <= currentYear; i++) {
      years.unshift(i);
    }
    return years;
  }

  generateArrayMonth() {
    const months = [
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    return months;
  }

  mounthToDigit(mounth) {
    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    return months.indexOf(mounth);
  }
}
