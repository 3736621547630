/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CreateCourseResponseDto } from './createCourseResponseDto';

export interface CreateStudentResponseDto { 
    name: string;
    lastName: string;
    isMember: boolean;
    isPresident: boolean;
    gender: CreateStudentResponseDto.GenderEnum;
    dateOfBirth: Date;
    id: number;
    isActive: boolean;
    course: CreateCourseResponseDto;
}
export namespace CreateStudentResponseDto {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}