import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent implements OnInit {
  public show: boolean = false;
  public forgotForm: FormGroup;
  public errorMessage: any;
  closeResult: string;

  constructor(
    private fb: FormBuilder,
    public authenticationService: AuthenticationService
  ) {
    this.forgotForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {}

  forgetPassword() {
    this.authenticationService.forgetPassword(this.forgotForm.value.email);
  }
}
