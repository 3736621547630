/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateActivityRequestDto { 
    title: string;
    place: string;
    dateAt: Date;
    startAt: Date;
    endAt: Date;
    mount: number;
    hasPayments: boolean;
    course: number;
}