// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  basePath: 'https://api.ladirectiva.cl',
  firebaseConfig : {
    apiKey: "AIzaSyD5qjjji18JU-VpPRDJdmjtWyL8C7xYPQ0",
    authDomain: "app-ladirectiva.firebaseapp.com",
    projectId: "app-ladirectiva",
    storageBucket: "app-ladirectiva.appspot.com",
    messagingSenderId: "113268592148",
    appId: "1:113268592148:web:2bf0ec652044f3a0886101",
    measurementId: "G-MRK3KXFNEK"
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
