import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Inject, TemplateRef, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivitiesService, DuesService, PaymentActivitiesService, PaymentsService, S3Service, StudentsService } from '../../../backend';
import { AuthenticationService } from '../../../shared/services/authentication.service';
import { HelperService } from '../../../shared/services/helper.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { AngularFireMessaging } from '@angular/fire/messaging';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  newForm: FormGroup;
  preview: string;
  student;
  file;
  due;
  page = {
    limit: 6,
    totalItems: 0,
    offset: 0,
  };
  @ViewChild('content') modalContent: TemplateRef<any>;
  showLoader = false;
  year;
  course;
  activityData;
  activity;
  payments;
  paymentId;
  public files : any = []
  rows = [];
  months2 = [
    {name: 'Marzo', value: "marzo"},
    {name: 'Abril', value: "abril"},
    {name: 'Mayo', value: "mayo"},
    {name: 'Junio', value: "junio"},
    {name: 'Julio', value: "julio"},
    {name: 'Agosto', value: "agosto"},
    {name: 'Septiembre', value: "septiembre"},
    {name: 'Octubre', value: "octubre"},
    {name: 'Noviembre', value: "noviembre"},
    {name: 'Diciembre', value: "diciembre"},
  ]
  types = [
    { name: 'Cuota', value: 'cuota' },
    { name: 'Actividad', value: 'actividad' },  
  ];
  months = [
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];
  public elem: any;
  public dark: boolean =
    this.layout.config.settings.layout_version == 'dark-only' ? true : false;

  constructor(private afMessaging: AngularFireMessaging,public layout: LayoutService, public navServices: NavService, private activitiesService: ActivitiesService, private paymentActivitiesService: PaymentActivitiesService, private duesService: DuesService, private http: HttpClient, private s3Service: S3Service, private studentsService: StudentsService ,private modalService: NgbModal, public authenticationService: AuthenticationService, private helperService: HelperService, private fb: FormBuilder, private sanitizer: DomSanitizer, private paymentsService: PaymentsService,) {

    this.newForm = this.fb.group({
      type: [, [Validators.required]],
      valueType: [[], [Validators.required]],
      imageB64: [,],
    });
  }

  requestPermission() {
    this.afMessaging.requestPermission
      .subscribe(
        (token) => { console.log('Permission granted! :', token); },
        (error) => { console.error(error); },  
      );
  }

  ngOnInit() {
    this.getData();
    this.requestPermission();
    console.log(this.authenticationService)
  
    
  }

  async getData(){
    try{
      this.student = this.authenticationService.currentStudent.id;
      this.course = this.authenticationService.currentStudent.course.id
      this.year = this.helperService.currentYear();
      localStorage.setItem("user", JSON.stringify(this.authenticationService.userData))
      localStorage.setItem("student", JSON.stringify(this.authenticationService.currentStudent))
      this.due = await this.getDue();
      this.getActivities();
      
      console.log("due: ", this.due)
      const { data } = await this.studentsService
          .studentsControllerFindByIdPayments(this.student, this.year)
          .toPromise();
  
        this.rows = this.months.map((month) => {
          const row = {
            month,
            payment: data['payments'].find((payment) => {
              return payment.monthPaid === month;
            }),
          };
  
          return row;
        });
      console.log(this.rows);
      }catch (e) {
        console.log(e)
      }
  }

  async getDue() {
    const { data } = await this.duesService
      .duesControllerListByCourse(this.year, this.course)
      .toPromise();
    return data.items[0];
  }

  async getActivities() {
    const { data } = await this.activitiesService
      .activitiesControllerListByCourse(
        this.year,
          this.course,
          "",
          this.page.limit,
          this.page.offset + 1
      )
      .toPromise();
    this.activityData = {
      ...data,
    };
    console.log("actividades",this.activityData)
    for(let payment of this.activityData.items){
      this.getDataPayments(payment)
    }
  }

  async generateS3Url() {
    const { data } = await this.s3Service.s3ControllerUploadFile().toPromise();
    return data;
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

      fileEntry.file(async (file: File) => {
        const headers = new HttpHeaders({
          'Content-Type': file.type,
        });
        this.file = file;
        const urlData = await this.generateS3Url();
        this.showLoader = true;
        this.http.put(urlData.signedUrl, file, { headers }).subscribe(() => {
          this.newForm.patchValue({ imageB64: urlData.url });
          console.log('File is uploaded');
          this.showLoader = false;
        });
      });
    }
  }

  filterMonths(){
    return this.rows.filter(x => x.payment === undefined)
  }

  filterActivitys(editValue?){
    
  }

  async getDataPayments(editValue?) {
    console.log(editValue)
    this.activity = editValue.id
    try {
      const { data } = await this.studentsService
        .studentsControllerListByCoursePaymentsActivities(
          this.activity,
          this.course
        )
        .toPromise();

      this.payments = data;
      for(let student of this.payments){
        console.log(student.paymentsActivities.length)
        if(student.paymentsActivities.length == 1 && student.id == this.student){
          this.activityData.items = this.activityData.items.filter((x) => x.id !== this.activity )
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async changedPayment() {
    if(this.newForm.controls.imageB64.value ==  null){
      this.newForm.controls.imageB64.patchValue("Sin Comprobante")
    }
    try {
      if(this.newForm.value.type == "cuota"){
        for(let month of this.newForm.value.valueType){
          await this.paymentsService
            .paymentsControllerCreate({
              mount: parseInt(this.due.mount, 10),
              student: parseInt(this.authenticationService.currentStudent.id, 10),
              monthPaid: month,
              isPay: false,
              imageB64: this.newForm.value.imageB64,
              monthPayment: new Date(
                new Date().setFullYear(
                  2023,
                  this.helperService.mounthToDigit(month)
                )
              ),
              course: parseInt(this.authenticationService.currentStudent.course.id, 10),
            })
            .toPromise();
        }
        this.modalService.dismissAll()
        this.showLoader = false;
      }
      if(this.newForm.value.type == "actividad"){
        await this.paymentActivitiesService
          .paymentsActivitiesControllerCreate({
            mount: parseInt(this.newForm.value.valueType.mount, 10),
            monthPaymentActivity: new Date(),
            activity: parseInt(this.newForm.value.valueType.id, 10),
            student: this.student,
            course: parseInt(this.course, 10),
            doc: this.newForm.value.imageB64,
            isPay: false
          })
          .toPromise();
        this.modalService.dismissAll()
        this.showLoader = false;
      }
    } catch (error) {
      this.showLoader = false;
      console.log(error);
    }
  }

  removeFile() {
    this.newForm.patchValue({ imageB64: '' });
  }

  async openModal(){
    this.getData();
    try{
      await this.modalService.open(this.modalContent, {
        windowClass: 'dark-modal',
        centered: true,
      }).result;
      this.showLoader = true;
      this.newForm.reset();
      this.showLoader = false;
    } catch (error) {
      this.showLoader = false;
      this.newForm.reset();
      console.log(error);
    }
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }
}
