export * from './activities.service';
import { ActivitiesService } from './activities.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './colleges.service';
import { CollegesService } from './colleges.service';
export * from './conventions.service';
import { ConventionsService } from './conventions.service';
export * from './courses.service';
import { CoursesService } from './courses.service';
export * from './dues.service';
import { DuesService } from './dues.service';
export * from './health.service';
import { HealthService } from './health.service';
export * from './paymentActivities.service';
import { PaymentActivitiesService } from './paymentActivities.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './releases.service';
import { ReleasesService } from './releases.service';
export * from './s3.service';
import { S3Service } from './s3.service';
export * from './students.service';
import { StudentsService } from './students.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [ActivitiesService, AuthService, CollegesService, ConventionsService, CoursesService, DuesService, HealthService, PaymentActivitiesService, PaymentsService, ReleasesService, S3Service, StudentsService, UsersService];
