/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateActivityRequestDto } from '../model/createActivityRequestDto';
import { CreateVoteActivityRequestDto } from '../model/createVoteActivityRequestDto';
import { ResponseWrapperForBoolean } from '../model/responseWrapperForBoolean';
import { ResponseWrapperForCreateActivityResponseDto } from '../model/responseWrapperForCreateActivityResponseDto';
import { ResponseWrapperForCreateVoteActivityResponseDto } from '../model/responseWrapperForCreateVoteActivityResponseDto';
import { ResponseWrapperForListCollageResponseDto } from '../model/responseWrapperForListCollageResponseDto';
import { ResponseWrapperForNumber } from '../model/responseWrapperForNumber';
import { UpdateActivityRequestDto } from '../model/updateActivityRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ActivitiesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerCount(observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForNumber>;
    public activitiesControllerCount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForNumber>>;
    public activitiesControllerCount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForNumber>>;
    public activitiesControllerCount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForNumber>('get',`${this.basePath}/v1/activities/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create activity
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerCreate(body: CreateActivityRequestDto, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreateActivityResponseDto>;
    public activitiesControllerCreate(body: CreateActivityRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreateActivityResponseDto>>;
    public activitiesControllerCreate(body: CreateActivityRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreateActivityResponseDto>>;
    public activitiesControllerCreate(body: CreateActivityRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling activitiesControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForCreateActivityResponseDto>('post',`${this.basePath}/v1/activities`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create vote
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerCreateVote(body: CreateVoteActivityRequestDto, id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreateVoteActivityResponseDto>;
    public activitiesControllerCreateVote(body: CreateVoteActivityRequestDto, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreateVoteActivityResponseDto>>;
    public activitiesControllerCreateVote(body: CreateVoteActivityRequestDto, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreateVoteActivityResponseDto>>;
    public activitiesControllerCreateVote(body: CreateVoteActivityRequestDto, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling activitiesControllerCreateVote.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling activitiesControllerCreateVote.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForCreateVoteActivityResponseDto>('post',`${this.basePath}/v1/activities/${encodeURIComponent(String(id))}/votes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete activity
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public activitiesControllerDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public activitiesControllerDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public activitiesControllerDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling activitiesControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForBoolean>('delete',`${this.basePath}/v1/activities/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get activity
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerFindById(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreateActivityResponseDto>;
    public activitiesControllerFindById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreateActivityResponseDto>>;
    public activitiesControllerFindById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreateActivityResponseDto>>;
    public activitiesControllerFindById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling activitiesControllerFindById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForCreateActivityResponseDto>('get',`${this.basePath}/v1/activities/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List activity
     * 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerList(limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListCollageResponseDto>;
    public activitiesControllerList(limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListCollageResponseDto>>;
    public activitiesControllerList(limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListCollageResponseDto>>;
    public activitiesControllerList(limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListCollageResponseDto>('get',`${this.basePath}/v1/activities`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List activities by course
     * 
     * @param year 
     * @param course 
     * @param find 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListCollageResponseDto>;
    public activitiesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListCollageResponseDto>>;
    public activitiesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListCollageResponseDto>>;
    public activitiesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling activitiesControllerListByCourse.');
        }

        if (course === null || course === undefined) {
            throw new Error('Required parameter course was null or undefined when calling activitiesControllerListByCourse.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (find !== undefined && find !== null) {
            queryParameters = queryParameters.set('find', <any>find);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListCollageResponseDto>('get',`${this.basePath}/v1/activities/course/${encodeURIComponent(String(course))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update activity
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activitiesControllerPut(body: UpdateActivityRequestDto, id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public activitiesControllerPut(body: UpdateActivityRequestDto, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public activitiesControllerPut(body: UpdateActivityRequestDto, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public activitiesControllerPut(body: UpdateActivityRequestDto, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling activitiesControllerPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling activitiesControllerPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForBoolean>('put',`${this.basePath}/v1/activities/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
