<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright text-center">
      <p class="mb-0">
        © {{ today | date: "y" }} La Directiva. Todos los derechos reservados.
      </p>
    </div>
  </div>
</div>
