/**
 * backend
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateReleaseRequestDto } from '../model/createReleaseRequestDto';
import { ResponseWrapperForBoolean } from '../model/responseWrapperForBoolean';
import { ResponseWrapperForCreateReleaseResponseDto } from '../model/responseWrapperForCreateReleaseResponseDto';
import { ResponseWrapperForListCollageResponseDto } from '../model/responseWrapperForListCollageResponseDto';
import { ResponseWrapperForNumber } from '../model/responseWrapperForNumber';
import { UpdateReleaseRequestDto } from '../model/updateReleaseRequestDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ReleasesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Count
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerCount(observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForNumber>;
    public releasesControllerCount(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForNumber>>;
    public releasesControllerCount(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForNumber>>;
    public releasesControllerCount(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForNumber>('get',`${this.basePath}/v1/releases/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create release
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerCreate(body: CreateReleaseRequestDto, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreateReleaseResponseDto>;
    public releasesControllerCreate(body: CreateReleaseRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreateReleaseResponseDto>>;
    public releasesControllerCreate(body: CreateReleaseRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreateReleaseResponseDto>>;
    public releasesControllerCreate(body: CreateReleaseRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling releasesControllerCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForCreateReleaseResponseDto>('post',`${this.basePath}/v1/releases`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete release
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerDelete(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public releasesControllerDelete(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public releasesControllerDelete(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public releasesControllerDelete(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling releasesControllerDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForBoolean>('delete',`${this.basePath}/v1/releases/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get release
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerFindById(id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForCreateReleaseResponseDto>;
    public releasesControllerFindById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForCreateReleaseResponseDto>>;
    public releasesControllerFindById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForCreateReleaseResponseDto>>;
    public releasesControllerFindById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling releasesControllerFindById.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForCreateReleaseResponseDto>('get',`${this.basePath}/v1/releases/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List release
     * 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerList(limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListCollageResponseDto>;
    public releasesControllerList(limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListCollageResponseDto>>;
    public releasesControllerList(limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListCollageResponseDto>>;
    public releasesControllerList(limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListCollageResponseDto>('get',`${this.basePath}/v1/releases`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List releases by course
     * 
     * @param year 
     * @param course 
     * @param find 
     * @param limit 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForListCollageResponseDto>;
    public releasesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForListCollageResponseDto>>;
    public releasesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForListCollageResponseDto>>;
    public releasesControllerListByCourse(year: number, course: number, find?: string, limit?: number, page?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling releasesControllerListByCourse.');
        }

        if (course === null || course === undefined) {
            throw new Error('Required parameter course was null or undefined when calling releasesControllerListByCourse.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (find !== undefined && find !== null) {
            queryParameters = queryParameters.set('find', <any>find);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ResponseWrapperForListCollageResponseDto>('get',`${this.basePath}/v1/releases/course/${encodeURIComponent(String(course))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update release
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public releasesControllerPut(body: UpdateReleaseRequestDto, id: number, observe?: 'body', reportProgress?: boolean): Observable<ResponseWrapperForBoolean>;
    public releasesControllerPut(body: UpdateReleaseRequestDto, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResponseWrapperForBoolean>>;
    public releasesControllerPut(body: UpdateReleaseRequestDto, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResponseWrapperForBoolean>>;
    public releasesControllerPut(body: UpdateReleaseRequestDto, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling releasesControllerPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling releasesControllerPut.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ResponseWrapperForBoolean>('put',`${this.basePath}/v1/releases/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
