import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from '../../backend';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnInit {
  public userData: any;
  public user: any;
  public showLoader: boolean = false;
  public students: any;
  public currentStudent: any;
  public $course = new BehaviorSubject(0);

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {
    this.userData = JSON.parse(localStorage.getItem('user'));
    this.currentStudent = JSON.parse(localStorage.getItem('student'));
    if (this.userData)
      this.authService.configuration.accessToken = this.userData['token'];
    this.$course.next(this.currentStudent?.course);
    console.log('current user', this.userData);
    console.log('current student', this.currentStudent);
  }

  ngOnInit(): void {}

  async login(email, password, checkbox, modalContent) {
    try {
      this.showLoader = true;
      const { data } = await this.authService
        .authControllerLogin({ email, password })
        .toPromise();
      this.userData = data;
      this.authService.configuration.accessToken = data['token'];

      if (this.userData.role !== 'admin') {
        this.students = data['students'];
        if (!this.students.length) {
          throw new Error('no-students');
        }
        console.log('aca', this.students);
        await this.openSelector(modalContent);
      }

      if (checkbox) {
        localStorage.setItem('user', JSON.stringify(this.userData));
        localStorage.setItem(
          'student',
          JSON.stringify(this.currentStudent || null)
        );
      }
      this.$course.next(this.currentStudent?.course);

      this.ngZone.run(() => {
        if (this.userData.role === 'admin') {
          this.router.navigate(['/admin']);
        } else {
          this.router.navigate(['/home']);
        }
      });
      this.showLoader = false;
    } catch (error) {
      console.log(error);
      this.showLoader = false;
      let message =
        'El servidor ha detectado un error. Intentalo de nuevo más tarde';

      if (error.status === 401) {
        message = 'Correo o contraseña inválido';
      }
      if (error.message === 'empty') {
        message = 'Debe seleccionar un estudiante para continuar';
      }
      if (error.message === 'no-students') {
        message = 'No tiene ningun estudiante activo';
      }

      this.toster.error(message, 'Error');
    }
  }

  async forgetPassword(email) {
    try {
      this.showLoader = true;
      await this.authService.authControllerResetPassword({ email }).toPromise();
      this.toster.success(
        'Se ha enviado un correo a tu cuenta de correo',
        'Éxito'
      );
      this.showLoader = false;
    } catch (error) {
      console.log(error);
      this.showLoader = false;
      let message =
        'El servidor ha detectado un error. Intentalo de nuevo más tarde';

      if (error.status === 401) {
        message = 'Correo inválido';
      }

      this.toster.error(message, 'Error');
    }
  }

  async resetPassword(password, token) {
    try {
      this.showLoader = true;
      const { data } = await this.authService
        .authControllerNewPassword({ password, token })
        .toPromise();
      if (data) {
        this.toster.success(
          'Se ha reiniciado su contraseña con éxito',
          'Éxito'
        );
        this.router.navigate(['/auth/login'], { replaceUrl: true });
      } else {
        this.toster.error('No se ha podido reiniciado su contraseña ', 'Error');
      }
      this.showLoader = false;
    } catch (error) {
      console.log(error);
      this.showLoader = false;
      let message =
        'El servidor ha detectado un error. Intentalo de nuevo más tarde';
      if (error.status === 400) {
        message = 'Token inválido';
      }
      this.toster.error(message, 'Error');
    }
  }

  get isLoggedIn(): boolean {
    return this.userData != null;
  }

  get isAdmin(): boolean {
    return this.userData.role === 'admin';
  }

  async openSelector(modalContent) {
    try {
      if (this.students.length > 1) {
        await this.modalService.open(modalContent, {
          windowClass: 'dark-modal',
          centered: true,
        }).result;
      } else {
        this.currentStudent = this.students[0];
      }
    } catch (error) {
      throw new Error('empty');
    }
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(['/auth/login'], { replaceUrl: true });
    window.location.reload();
  }

  getCourse(): Observable<any> {
    return this.$course.asObservable();
  }
}
